@success: #198754;
@danger: #ff4d4f;

:root:root, :root {
  --adm-color-primary: #e30613 !important;
}


body {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 28px !important;
  background-color: var(--adm-color-primary) !important;
}

.btn {
}

.btn:hover {
  cursor: pointer !important;
  color: black;
}

.btn:active {
  text-decoration: underline;
}

.disabled, .disabled:hover, .disabled:focus {
  cursor: not-allowed !important;
  text-decoration: none !important;
  color: black;
}


aside {
  height: 100vh;
  overflow: scroll;
}

footer {
  text-align: center;
}

.ant-layout-footer {
  color: gray;
}

main {
  min-height: calc(100vh - 70px - 1px) !important; /* fill whole screen*/
  padding: 24px;
  margin: 0;
}

.scrollable {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}

// Hide unwanted scrollbars
aside, section, main, .scrollable {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: thin; /* Firefox */
}

aside::-webkit-scrollbar, section::-webkit-scrollbar, main::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.cursor-pointer:hover {
  cursor: pointer;
}

.ant-notification {
  z-index: 9999;
}

.ant-table-body {
  overflow-y: auto !important;
  overflow-x: auto !important;
}


.ant-table-wrapper, .ant-table-wrapper > *, .ant-table-wrapper > * > *, .ant-table, .ant-table-footer {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  padding: 0 !important;
  background-color: transparent !important;
}

.rounded-table(@radius) {
  tr:first-child th:first-child {
    -moz-border-radius-topleft: @radius;
    -webkit-border-top-left-radius: @radius;
    border-top-left-radius: @radius;
  }
  tr:first-child th:last-child {
    -moz-border-radius-topright: @radius;
    -webkit-border-top-right-radius: @radius;
    border-top-right-radius: @radius;
  }
  tr:last-child td:first-child {
    -moz-border-radius-bottomleft: @radius;
    -webkit-border-bottom-left-radius: @radius;
    border-bottom-left-radius: @radius;
  }
  tr:last-child td:last-child {
    -moz-border-radius-bottomright: @radius;
    -webkit-border-bottom-right-radius: @radius;
    border-bottom-right-radius: @radius;
  }
}

[data-slate-editor] > * + * {
  margin-top: -.75em;
}

[report-editor] > * + * {
  margin-top: 1em;
}

.ant-menu-item {
  font-size: xx-large !important;
  margin-bottom: 24px !important;
  align-content: center;
}

.clickable {
  border: 1px solid transparent !important;
  cursor: pointer;
}

.clickable:hover {
  border: 1px solid gray !important;
  border-radius: 8px !important;
}

.clickable:active {
  border: 1px solid gray !important;
  cursor: grabbing !important;
}

.ant-space-item:has(#selfCheckInList) {
  flex-basis: 70px !important;
  flex-grow: 1;
  overflow: hidden;
}


.action-delete {
  color: @danger !important;
  border-color: @danger !important;
  background: #fff;
}

.action-delete:hover {
  scale: 130%;
}


// hide everything in print mode except the id qr-view
@media print {
  *, .ant-layout{
    --adm-color-primary: #fff !important;
    background-color: #fff;
    height: auto;
  }

  header *, footer *, .ant-layout-header, .ant-layout-footer, .ant-btn, .ant-card-head * {
    display: none;
  }

  main {
    padding: 0;
    height: auto !important;
    min-height: auto;
  }

  .ant-card {
    box-shadow: none;
    border: none;
  }

  .hideOnPrint {
    display: none;
  }

  #qr-view {
    display: block;
    position: fixed;
    top: 1cm;
    left: 3cm;
    right: 3cm;
    width: 10cm;
  }

  .ant-qrcode {
    width: auto !important;
    height: auto !important;
  }
  .ant-qrcode > canvas {
    width: 10cm !important;
    height: 10cm !important;
  }

  root, root:root {
    height: 100vh !important;
    width: 90vw !important;
    overflow: hidden;
  }
}